import React, { Component, Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './loading.css'

import SpinnerImg from '../../assets/spinner.gif';

export function Loading() {
  const [isLoading, setIsLoading] = useState(true);
  const [fadeOutLoading, setFadeOutLoading] = useState(false);
  const location = useLocation(); 

  useEffect(() => {
    let isMounted = true;

    const handleImageLoad = () => {
      const images = Array.from(document.images);
      const imagePromises = images.map(img => {
        if (img.complete) {
          return Promise.resolve(img.naturalHeight !== 0);
        }
        return new Promise((resolve) => {
          const onLoad = () => {
            resolve(true);
            img.removeEventListener('load', onLoad);
            img.removeEventListener('error', onError);
          };
          const onError = () => {
            resolve(false);
            img.removeEventListener('load', onLoad);
            img.removeEventListener('error', onError);
          };
          img.addEventListener('load', onLoad);
          img.addEventListener('error', onError);
        });
      });

      Promise.all(imagePromises).then(() => {
        if (isMounted) {
          setTimeout(() => setFadeOutLoading(true), 300);
          setTimeout(() => setIsLoading(false), 900);
        }
      });
    };

    window.addEventListener('load', handleImageLoad);
    window.onbeforeunload = function(e){
        setFadeOutLoading(false);
        setTimeout(() => setIsLoading(true), 100);
    };

    setTimeout(() => {
      if (isMounted) {
        setFadeOutLoading(true);
        setTimeout(() => setIsLoading(false), 600);
      }
    }, 2000);

    return () => {
      isMounted = false;
      window.removeEventListener('load', handleImageLoad);
    };
  }, [location.pathname]);

  return performance.navigation.type == 2 || !isLoading ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className={fadeOutLoading ? "fade-out loading-content" : "loading-content"}>
        <div className="spinner">
          <img src={SpinnerImg} alt="Loading" />
          <p>Loading...</p>
        </div>
      </div>
    </Fragment>
  );
}
