import React, { Component, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import './NavMenu.css';
import { useLocation } from 'react-router-dom';

import LogoImg from '../../assets/navMenu/logo.png';
import ShopImg from '../../assets/navMenu/shop.png';
import ShopSelectedImg from '../../assets/navMenu/shop_selected.png';
import IllustrationImg from '../../assets/navMenu/illustration.png';
import IllustrationSelectedImg from '../../assets/navMenu/illustration_selected.png';
import AboutImg from '../../assets/navMenu/about.png';
import AboutSelectedImg from '../../assets/navMenu/about_selected.png';
import ContactImg from '../../assets/navMenu/contact.png';
import ContactSelectedImg from '../../assets/navMenu/contact_selected.png';

export function NavMenu(props) {
  const displayName = NavMenu.name;
  
  const buttons = [
      { normal: ShopImg, active: ShopSelectedImg },
      { normal: IllustrationImg, active: IllustrationSelectedImg },
      { normal: AboutImg, active: AboutSelectedImg },
      { normal: ContactImg, hover: ContactSelectedImg }
  ];
  
  const handleMouseOver = (e, hoverImage) => {
      e.currentTarget.src = hoverImage;
    }
  
  const handleMouseOut = (e, normalImage) => {
      e.currentTarget.src = normalImage;
    }

    const [state, setState] = useState({ collapsed: true });
    const toggleNavbar = () => {
       setState({ collapsed: !state.collapsed });
    }

  var location = useLocation();
  return (
      <header>
        <Navbar className="navbar-expand-md navbar-toggleable-sm ng-white box-shadow mb-3" light>
          <Container>
            <NavbarBrand href="/">
                <img src={LogoImg} className="logo" alt="Logo" />
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-md-inline-flex flex-md-row-reverse" isOpen={!state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink className="text-dark">
                    <img src={location.pathname === '/shop' ? ShopSelectedImg : ShopImg} alt="Shop" className="img-fluid" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="/illustration">
                    <img src={location.pathname === '/illustration' ? IllustrationSelectedImg : IllustrationImg} alt="Illustration" className="img-fluid" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" href="/about">
                       <img src={location.pathname === '/about' ? AboutSelectedImg : AboutImg} alt="About" className="img-fluid" />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <a className="text-dark nav-link" href="mailto:contact@annashepeta.art">
                      <img src={ContactImg} alt="Contact" className="img-fluid" onMouseOver={(e) => handleMouseOver(e, ContactSelectedImg)} onMouseOut={(e) => handleMouseOut(e, ContactImg)} />
                  </a>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
}
