import React, { Component, Fragment } from 'react';
import './about.css'
import { NavMenu } from '../navMenu/NavMenu';

import AboutMainImg from '../../assets/about/about_main.png';

export function About() {
    return (
         <Fragment>
            <div className="about-content">
              <NavMenu />
              <div className="about-info">
                <img src={AboutMainImg} alt="About" className="img-fluid about-main-img" />
                <div className="about-info-text">
                    <p align="center"><u>Nice to meet you!</u></p>
                    <p>I'm Anna Shepeta, a neurodivergent artist and illustrator. I live by the Baltic Sea in Poland with my beloved partner and our slightly naughty cat. I love traveling, seaside walks, collecting pine cones, stones, and shells, and  I also enjoy coffee with orange juice :-)</p>
                    <p>I've been drawing since I was a child. I have a passion for exploring new materials, which has led me to work with oil paints, acrylics, oil pastels, ink, colored pencils and watercolors. I've also kept sketch diaries and created small comics. Beyond paper and canvases, I've woven tapestries and worked with ceramics. Currently, my passion is knitting and crocheting.</p>
                    <p align="center"><u>About my work</u></p>
                    <p>I also illustrate books and create artwork for various projects. Since becoming an illustrator in 2020, I've worked with publishers like Nosy Crow, Quarto, Ivy Kids, Albatros Media, HarperCollins Publishers. I've been part of the Plum Agency team and collaborated with Society6 & Pixar.</p>
                    <p>You can see some of my work on my <a href="/illustration">illustration</a> page.</p>
                    <p>If you'd like to support me, feel free to visit my <a href="/shop">shop</a>.</p>
                </div>
              </div>
          </div>
         </Fragment>
     );
}
