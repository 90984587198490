import React, { Component, Fragment, useEffect } from 'react';
import { Container } from 'reactstrap';
import DisableDevtool from 'disable-devtool';
import TextFont from '../assets/letmeshowyou_font.otf';

export function Layout (props) {
    const displayName = Layout.name;
    useEffect(() => {
    DisableDevtool({
            clearLog: 'true',
            disableSelect: 'true',
            disableCopy: 'true',
            disableCut: 'true',
            disablePaste: 'true',
            url: 'about:blank'
          });

      window.oncontextmenu = function(event) {
           event.preventDefault();
           event.stopPropagation();
           return false;
      };
    }, []);

    return (
     <Fragment>
        <style> @font-face {'{'} font-family: 'LetMeShowYou'; src: url({TextFont}) format('opentype'); {'}'} </style>
        {props.children}
     </Fragment>
    );
}
