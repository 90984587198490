import React, { Component, Fragment, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { LinkTree } from './components/linktree/LinkTree'
import { Home } from './components/home/Home';
import { Loading } from './components/loading/Loading';
import { About } from './components/about/About';
import { AccessDenied } from './components/errors/accessDenied/AccessDenied';
import { NotFound } from './components/errors/notFound/NotFound';
import { MediaGallery } from './components/gallery/MediaGallery';
import { Shop } from './components/shop/Shop';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { ShopItemCategoriesList } from './components/shop/management/shopItemCategories/ShopItemCategoriesList';
import { ShopItemsList } from './components/shop/management/shopItems/ShopItemsList';
import { ShopItemDetails } from './components/shop/item/details/ShopItemDetails';
import { ShopDiscountList } from './components/shop/management/discounts/ShopDiscountList';
import 'animate.css';

import './custom.css'

export default function App() {
  const displayName = App.name;

    return (
      <Fragment>
          <Loading />
          <Layout>
             <Switch>
                /* Legacy */
                 <Route exact path='/links' component={LinkTree} />
                 
                /* General info */
                 <Route exact path='/' component={Home} />
                 <Route exact path='/illustration' component={MediaGallery} />
                 <Route exact path='/about' component={About} />

                /* Errors */
                 <Route exact path='/403' component={AccessDenied} />
                 <Route exact path='/404' component={NotFound} />

                 /* Authorization */
                 <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />

                 /* Fallback */
                 <Route path="*" render={() => <Redirect to="/404" />} />
             </Switch>
          </Layout>
      </Fragment>
    );
}
