import React, { Component, Fragment } from 'react';
import './linkTree.css'
import backgroundImage from '../../assets/linkTree/background.JPG';

import portfolioBtn from '../../assets/linkTree/portfolio.PNG';
import shopBtn from '../../assets/linkTree/shop.PNG';
import kofiBtn from '../../assets/linkTree/kofi.PNG';
import contactBtn from '../../assets/linkTree/contact.PNG';

import portfolioSelectedBtn from '../../assets/linkTree/portfolio_selected.PNG';
import shopSelectedBtn from '../../assets/linkTree/shop_selected.PNG';
import kofiSelectedBtn from '../../assets/linkTree/kofi_selected.PNG';
import contactSelectedBtn from '../../assets/linkTree/contact_selected.PNG';

export class LinkTree extends Component {
  static displayName = LinkTree.name;
  
buttons = [
  { normal: portfolioBtn, hover: portfolioSelectedBtn },
  { normal: shopBtn, hover: shopSelectedBtn },
  { normal: kofiBtn, hover: kofiSelectedBtn },
  { normal: contactBtn, hover: contactSelectedBtn },
];
  
 handleMouseOver = (e, hoverImage) => {
    e.currentTarget.src = hoverImage;
  }

  handleMouseOut = (e, normalImage) => {
    e.currentTarget.src = normalImage;
  }

  render () {
    return (
         <Fragment>
             <div className="links-content">
                <img src={backgroundImage} className="full-page-background" />
                <div class="overlay-content-items">
                     
                    <a href="https://www.behance.net/halbensch?fbclid=PAAaYx0spdpcbmHB_R3yah4h0lSXmaJ0G1DmHoN2c65VgEOmkSAvOhViTy-OY" className="overlay-link">
                      <img
                        src={portfolioBtn}
                        className="link-img"
                        onMouseOver={e => this.handleMouseOver(e, portfolioSelectedBtn)} 
                        onMouseOut={e => this.handleMouseOut(e, portfolioBtn)} />
                    </a>
                     
                     <a href="https://www.redbubble.com/people/halbensch/shop?asc=u&fbclid=PAAaY5QJKkdla320NfSsKhFVw25UwiGjWaLkUu7Zq8FLHYe8QuP6meXFkREhk" className="overlay-link">
                       <img
                       src={shopBtn}
                       className="link-img"
                       onMouseOver={e => this.handleMouseOver(e, shopSelectedBtn)} 
                       onMouseOut={e => this.handleMouseOut(e, shopBtn)} />
                     </a>
                 
                    <a href="https://ko-fi.com/halbermensch?fbclid=PAAaaDsOXTWJCv6uEHzz_UJUw8U3sj0ltOfbYiUxq35-HEZWd8_xunyBH6QXk" className="overlay-link">
                      <img
                          src={kofiBtn}
                          className="link-img"
                          onMouseOver={e => this.handleMouseOver(e, kofiSelectedBtn)} 
                          onMouseOut={e => this.handleMouseOut(e, kofiBtn)} />
                    </a>
                 
                     <a href="mailto:contact@annashepeta.art" className="overlay-link contact-link">
                       <img
                            src={contactBtn}
                            className="link-img"
                            onMouseOver={e => this.handleMouseOver(e, contactSelectedBtn)} 
                            onMouseOut={e => this.handleMouseOut(e, contactBtn)} />
                     </a>
                     
                </div>
             </div>
         </Fragment>
     );
  }
}
