import React, { Component, Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './mediaGallery.css'
import { Gallery } from "react-grid-gallery";
import 'react-image-lightbox/style.css'; 
import { NavMenu } from '../navMenu/NavMenu';
import { ApiEndpoints } from '../../api/apiEndpoints';
import { MediaGalleryLightBox } from './MediaGalleryLightBox';
import { ClickOnRenderedImage } from './ClickOnRenderedImage';

import ArtImg from '../../assets/gallery/art.png';
import ArtSelectedImg from '../../assets/gallery/art_selected.png';
import PortfolioImg from '../../assets/gallery/portfolio.png';
import PortfolioSelectedImg from '../../assets/gallery/portfolio_selected.png';

export function MediaGallery () {
  const displayName = MediaGallery.name;

  const query = new URLSearchParams(useLocation().search);  
  const [tabIndex, setTabIndex] = useState(query.get("tab") == 1 ? 1 : 0);
  
  const [portfolioImages, setPortfolioImages] = useState([]);
  const [artImages, setArtImages] = useState([]);
  const [isClearing, setIsClearing] = useState(false);
  
    useEffect(() => {
      const fetchImages = async () => {
        try {
          const response = await fetch(ApiEndpoints.Illustration);
          const data = await response.json();
          
          const formatImage = (item: any) => ({
            src: ApiEndpoints.PublicFile + "/" + item.thumbnailFileName,
            original: ApiEndpoints.PublicFile + "/" + item.originalFileName,
            height: item.thumbnailHeight,
            width: item.thumbnailWidth,
            caption: item.description,
          });

          setPortfolioImages(data.portfolioImages.map(formatImage));
          setArtImages(data.artImages.map(formatImage));

        } catch (error) {
          console.error('Error fetching images', error);
        }
      };
  
      fetchImages();
    }, []);

  const tabs = [
    { normal: PortfolioImg, selected: PortfolioSelectedImg },
    { normal: ArtImg, selected: ArtSelectedImg },
  ];
  
  const [portfolioIndex, setPortfolioIndex] = useState(-1);
  const [artIndex, setArtIndex] = useState(-1);

  const handleClick = (index, item) => {
      tabIndex === 0 ? setPortfolioIndex(index) : setArtIndex(index);
  }

  const handleTabClick = (index) => {
      if (tabIndex !== index) {
        setIsClearing(true);
        setTimeout(() => {
          setTabIndex(index);
          setIsClearing(false); 
        }, 50);
      }
    };

  return (
    <div className="gallery-content">
      <NavMenu />
         <div className="gallery-tabs">
            <a className={tabIndex === 0 ? 'active' : ''} onClick={() => handleTabClick(0)}>
                <img src={tabIndex === 0 ? tabs[0].selected : tabs[0].normal} alt="Portfolio" className="img-fluid" />
            </a>
            <a className={tabIndex === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}>
                <img src={tabIndex === 1 ? tabs[1].selected : tabs[1].normal} alt="Art" className="img-fluid" />
            </a>
        </div>
      { query.get("selected") && <ClickOnRenderedImage targetSrc={query.get("selected")} /> }
      <Gallery
        images={isClearing ? [] : (tabIndex === 0 ? portfolioImages : artImages)}
        onClick={handleClick}
        enableImageSelection={false}
        rowHeight={250}
        margin={7}
        tileViewportStyle={{ height: 250 }}
      />
      <MediaGalleryLightBox
          images={tabIndex === 0 ? portfolioImages : artImages}
          index={tabIndex === 0 ? portfolioIndex : artIndex}
          setIndex={tabIndex === 0 ? setPortfolioIndex : setArtIndex}
      />
    </div>
  );
}
