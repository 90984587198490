export class ApiEndpoints {
    static Illustration = '/api/illustration';
    static PublicFile = '/api/publicFile';

    /* Shop */
    static ShopFilters = '/api/shopFilters';
    static ShopItems = '/api/shopItems';

    /* Shop management */
    static ManageShopItemCategories = '/api/management/shopItemCategories';
    static ReorderShopItemCategories = '/api/management/shopItemCategories/reorder';
    static ManageShopItems = '/api/management/shopItems';
    static ReorderShopItems = '/api/management/shopItems/reorder';
    static ManageShopDiscounts = '/api/management/shopDiscounts';
    static SuggestUsersForDiscounts = '/api/management/shopDiscounts/suggestUsers';
    static SuggestShopItemsForDiscounts = '/api/management/shopDiscounts/suggestShopItems';
    static SuggestShopItemCategoriesForDiscounts = '/api/management/shopDiscounts/suggestShopItemCategories';
}